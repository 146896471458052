import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CategoryIcon from '@material-ui/icons/Category'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import CodeIcon from '@material-ui/icons/Code'
import BuildIcon from '@material-ui/icons/Build'
import BugReportIcon from '@material-ui/icons/BugReport'
import Colors from '../utils/Colors'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '900px',
    color: 'black',
    textAlign: 'center',
    backgroundImage:
      'linear-gradient(121deg, rgba(255, 255, 255, 0.04), rgba(	238, 238, 238, 1))',
  },
  item: {
    minHeight: '450px',
    padding: '20px',
    maxWidth: '500px',
  },
  button: {
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
  icon: {
    height: '100px',
  },
  para: {
    maxWidth: '400px',
    margin: 'auto',
  },
}))
const Services = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      spacing={15}
      alignItems="center"
      justify="center"
      className={classes.content}
      name="services"
    >
      <Grid item sm={6} lg={4} className={classes.item}>
        <div className="icon">
          <CategoryIcon
            color="primary"
            style={{ fontSize: 80, color: Colors.secondary }}
          ></CategoryIcon>
        </div>
        <Typography variant="h4" component="h2" gutterBottom>
          Prototyping
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          paragraph
          className={classes.para}
        >
          You’ve drawn it on a napkin. Now someone needs to model and 3D print
          it. You need to contact 3 or 4 suppliers to get a price. That’s what
          we do. We speak the language and travel to the right suppliers who can
          make your ideas happen. We get a sample on your desk that allows you
          to make the winning sales pitch.
        </Typography>
        {/* <Button
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Learn more
        </Button> */}
      </Grid>

      <Grid item sm={6} lg={4} className={classes.item}>
        <div className="icon">
          <AccountTreeIcon
            style={{ fontSize: 80, color: Colors.secondary }}
            color="primary"
          ></AccountTreeIcon>
        </div>
        <Typography variant="h4" component="h2">
          Product Development
        </Typography>
        <Typography
          variant="body1"
          paragraph
          gutterBottom
          className={classes.para}
        >
          You’ve got a market. Maybe orders already. But finding the factory who
          can make your product reliably and understand your needs is the
          challenge. Let us find the right supplier and explain your SoW in
          Chinese. We can run you from scope to full production in under 6
          months.
        </Typography>
        {/* <Button
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Learn more
        </Button> */}
      </Grid>
      <Grid item sm={6} lg={4} className={classes.item}>
        <div className="icon">
          <CodeIcon
            style={{ fontSize: 80, color: Colors.secondary }}
          ></CodeIcon>
        </div>
        <Typography variant="h4" component="h2">
          IOT
        </Typography>
        <Typography
          variant="body1"
          paragraph
          gutterBottom
          className={classes.para}
        >
          Smart? Of course. We have relationships with the largest developers
          who can create and host your app. We can source the hardware and
          update your PCB to move your product from the 20th to the 21st
          century. Our team in the US and China coordinate to ensure your App is
          suitable for Native users.
        </Typography>
        {/* <Button
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Learn more
        </Button> */}
      </Grid>
      <Grid item sm={6} lg={6} className={classes.item}>
        <div className="icon">
          <BuildIcon
            style={{ fontSize: 80, color: Colors.secondary }}
          ></BuildIcon>
        </div>
        <Typography variant="h4" component="h2">
          Manufacturing
        </Typography>
        <Typography
          variant="body1"
          paragraph
          gutterBottom
          className={classes.para}
        >
          You know exactly what you need. You just need it at a better price. We
          will value engineer your product and confidentially run it past key
          suppliers we trust. This will ensure you have IP protection and that
          the partner we find will make your product for the agreed price.
        </Typography>
        {/* <Button
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Learn more
        </Button> */}
      </Grid>
      <Grid item sm={6} lg={6} className={classes.item}>
        <div className="icon">
          <BugReportIcon
            style={{ fontSize: 80, color: Colors.secondary }}
          ></BugReportIcon>
        </div>
        <Typography variant="h4" component="h2">
          Quality Control
        </Typography>
        <Typography
          variant="body1"
          paragraph
          gutterBottom
          className={classes.para}
        >
          Do you speak Chinese? We do. We can be on the factory floor in 24
          hours and ensure your production run is to specifications. Issues and
          requested improvements are scoped in English with you and coordinated
          in Chinese at the factory. Just because you get a better price doesn’t
          mean you need to get an inferior product. iphones are made in China.
        </Typography>
        {/* <Button
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Learn more
        </Button> */}
      </Grid>
    </Grid>
  )
}

export default Services
