import React, { useRef, useState } from 'react'
//import  SplahImage from './images/splashImage'
import { makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Grid } from '@material-ui/core'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import SendIcon from '@material-ui/icons/Send'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: 'center',
    textShadow: '0.1px 0.1px #000026',
    backgroundColor: 'white',
  },
  textfield: {
    width: '50%',
    paddingBottom: '25px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  textarea: {
    //  padding: '20px',
    width: '50%',
    paddingBottom: '25px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  content: {
    minHeight: '900px',

    backgroundImage:
      'linear-gradient(121deg, rgba(255, 255, 255, 0.04), rgba(	238, 238, 238, 1))',
  },
  image: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))',
    opacity: '0.5',
  },
  contentCenter: {
    margin: 'auto',
    padding: '45px',
  },
}))
const ContactUs = () => {
  const classes = useStyles()
  const bgRef = useRef()
  const [submitted, submitForm] = useState(false)
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "contactUs.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const handle = event => {
    event.preventDefault()
    let myForm = document.getElementById('contactform')
    let formData = new FormData(myForm)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => submitForm(true))
      .catch(error => alert(error))
  }
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      ref={bgRef}
      fluid={imageData}
      backgroundColor={`#040e18`}
      onStartLoad={() => bgRef.current.selfRef.classList.toggle('loading')}
      onLoad={() => bgRef.current.selfRef.classList.toggle('loading')}
      className={classes.image}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.content}
        name="contact-us"
      >
        <Grid item sm={7} className={classes.header}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contentCenter}
          >
            <Grid item sm={12}>
              <Typography variant="h2">Contact Us</Typography>
            </Grid>
            {submitted && (
              <Grid item sm={12}>
                <Typography variant="body2">
                  Thank you for your message. We received it correctly and will
                  respond as soon as possible
                </Typography>
              </Grid>
            )}
            {!submitted && (
              <Grid item sm={12}>
                <form
                  className={classes.root}
                  autoComplete="off"
                  data-netlify="true"
                  method="POST"
                  name="contact form"
                  id="contactform"
                >
                  <input type="hidden" name="form-name" value="contact form" />
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.contentCenter}
                  >
                    <Grid item sm={12}>
                      <FormControl fullWidth className={classes.textfield}>
                        <TextField
                          color="secondary"
                          label="Your name"
                          required="true"
                          name="name"
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <FormControl fullWidth className={classes.textfield}>
                        <TextField
                          color="secondary"
                          label="Your email"
                          name="email"
                          required="true"
                          type="email"
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <FormControl fullWidth className={classes.textarea}>
                        <TextField
                          id="standard-multiline-static"
                          label="Your big idea"
                          name="message"
                          multiline
                          rows={4}
                          defaultValue=""
                          color="secondary"
                          required="true"
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <div data-netlify-recaptcha="true"></div>
                    </Grid>
                    <Grid item sm={6} className={classes.textarea}>
                      <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<SendIcon />}
                        type="submit"
                        onClick={handle}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BackgroundImage>
  )
}

export default ContactUs
