import React from 'react'
import ContactUs from '../components/contact_us'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Colors from '../utils/Colors'
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'

import Splash from '../components/splash'
import ServicesCard from '../components/services_card'
import Products from '../components/products'
import Services from '../components/services'
const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.principal,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
})
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const IndexPage = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Home" />
        <Splash />
        <ServicesCard />
        <Services />
        <Products />
        <ContactUs />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
