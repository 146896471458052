import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Img from 'gatsby-image'
import * as Scroll from 'react-scroll'
import Colors from '../utils/Colors'
const useStyles = makeStyles(theme => ({
  header: {
    color: Colors.principal,
    textShadow: '0.1px 0.1px #000026',
    textAlign: 'center',
    marginTop: '70px',
    marginBottom: '70px',
  },
  content: {
    // minWidth: '700px',
    color: Colors.principal,
    //backgroundColor: 'rgba(255,255,255,0)',
    // backgroundImage: 'linear-gradient(216deg, #f4f4f6 0%,#ffffff 100%)',
    backgroundImage:
      'linear-gradient(121deg, rgba(0, 133, 242, 0.20), rgba(238, 239, 240 , 0.1))',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))',
    opacity: '0.5',
  },
  root: {
    maxWidth: 400,
    margin: 'auto',
    height: '600px',
    marginBottom: '25px',
    marginTop: '15px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  listItem: {
    flexDirection: 'column',
  },
  button: {
    marginTop: '15px',
    flexDirection: 'column',
    margin: 'auto',
  },
  cardHeader: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.secondaryText,
  },
  cardContent: {
    minHeight: '170px',
  },
  cardAction: {
    margin: 'auto',
  },
}))
const ServicesCard = () => {
  const data = useStaticQuery(
    graphql`
      query {
        entrepreneur: file(relativePath: { eq: "entrepreneur.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        product: file(relativePath: { eq: "product_development.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        startup: file(relativePath: { eq: "startup.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const entre = data.entrepreneur.childImageSharp.fluid
  const product = data.product.childImageSharp.fluid
  const startup = data.startup.childImageSharp.fluid
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      spacing={15}
      alignItems="center"
      justify="center"
      className={classes.content}
      name="persona"
    >
      {/* <Grid item sm={11}>
        <Typography variant="h3">How can we help you ?</Typography>
      </Grid> */}

      <Grid item lg={4} sm={11} className={classes.header}>
        <Card className={classes.root} raised={true}>
          <CardHeader
            title="Entrepreneur"
            color="secondary"
            className={classes.cardHeader}
          />
          <CardMedia title="Entrepreneur portrait">
            <Img fluid={entre} />
          </CardMedia>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              This isn’t your first rodeo. You know this idea has a good chance
              to stick. You’ve got the right network and some seed capital. To
              be profitable fast, your product idea needs to be made in Asia.
            </Typography>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                Scroll.scroller.scrollTo('services', {
                  duration: 500,
                  delay: 100,
                  smooth: true,
                  offset: -100,
                })
              }}
            >
              Let us connect you.
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item lg={4} sm={11} className={classes.header}>
        <Card className={classes.root} raised={true}>
          <CardHeader
            title="Product Development"
            className={classes.cardHeader}
          />
          <CardMedia title="Entrepreneur portrait">
            <Img fluid={product} />
          </CardMedia>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              You’re reaching some good volumes and market penetration with your
              key product. But it needs some value engineering. There are just
              too many pieces and you don’t know how to coordinate multiple
              suppliers in China to coordinate a more cost effective product
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                Scroll.scroller.scrollTo('services', {
                  duration: 500,
                  delay: 100,
                  smooth: true,
                  offset: -100,
                })
              }}
            >
              We do. See how.
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item lg={4} sm={11} className={classes.header}>
        <Card className={classes.root} raised={true}>
          <CardHeader title="Startup" className={classes.cardHeader} />
          <CardMedia title="Entrepreneur portrait">
            <Img fluid={startup} />
          </CardMedia>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              It’s all mostly on paper. But the excitement is sky-high. To get
              some capital you’re going to need an MVP. To carve a space into
              your market you’re going to need to be cost-effective and squeeze
              out good margins to fund your business onwards. But you are the
              R&D department.
              {/* <List dense={false} >
              <ListItem className={classes.list}>
                  <ListItemText
                    primary="Single-line item"
                  />
              </ListItem>
              <ListItem className={classes.list}>
                  <ListItemText
                    primary="Single-line item"
                  />
              </ListItem>
              <ListItem className={classes.list}>
                  <ListItemText
                    primary="Single-line item"
                  />
              </ListItem>
              </List> */}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                Scroll.scroller.scrollTo('services', {
                  duration: 500,
                  delay: 100,
                  smooth: true,
                  offset: -100,
                })
              }}
            >
              Let us be your R&D department.
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ServicesCard
