import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import BackgroundImage from 'gatsby-background-image'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import PlayForWorkIcon from '@material-ui/icons/PlayForWork'
import './splash.css'
import * as Scroll from 'react-scroll'
import Colors from '../utils/Colors'

const useStyles = makeStyles(theme => ({
  header: {
    backgroundImage:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(	238, 238, 238, 0.7))',
    color: Colors.principalText,
    textAlign: 'center',
    padding: '25px',
  },
  content: {
    minHeight: '900px',
    color: Colors.principalText,
  },
  image: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))',
    opacity: '0.5',
  },
  blueColor: {
    color: Colors.secondaryDark,
  },
  body: {
    textAlign: 'left',
  },
}))
const Splash = props => {
  const classes = useStyles()
  const bgRef = useRef()
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "splash.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      ref={bgRef}
      fluid={imageData}
      backgroundColor={`#040e18`}
      onStartLoad={() => bgRef.current.selfRef.classList.toggle('loading')}
      onLoad={() => bgRef.current.selfRef.classList.toggle('loading')}
      className={classes.image}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.content}
      >
        <Grid item md={12} xl={6}></Grid>
        <Grid item xl={3} className={classes.header}>
          <Typography variant="h1" gutterBottom>
            Qiao
          </Typography>
          <Typography
            variant="paragraph"
            display="block"
            gutterBottom
            className={classes.body}
          >
            <ul>
              <li>Pronounced /tʃaʊ/ , like ‘Ciao’</li>
              <li>Mandarin for “Bridge”</li>
              <li>
                Let us be the bridge between your idea and a final product
              </li>
            </ul>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<PlayForWorkIcon />}
            onClick={() => {
              Scroll.scroller.scrollTo('persona', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
              })
            }}
          >
            Learn how we can help
          </Button>
        </Grid>
      </Grid>
    </BackgroundImage>
  )
}

export default Splash
