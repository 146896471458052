import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@material-ui/core/Button'
import Colors from '../utils/Colors'
import * as Scroll from 'react-scroll'
const useStyles = makeStyles(theme => ({
  gridItem: {
    minHeight: '300px',
    margin: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
  },
  gridItemEven: {
    minHeight: '300px',
    margin: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
  },
  content: {
    minHeight: '900px',
    color: Colors.principalText,
  },
  title: {
    paddingBottom: '50px',
  },
  text: {
    textAlign: 'center',
  },
  button: {
    marginTop: '15px',
  },
  row: {
    backgroundImage:
      'linear-gradient(121deg, rgba(0, 133, 242, 0.20), rgba(238, 239, 240 , 0.1))',
    padding: '75px',
  },
  rowNotEven: {
    backgroundImage:
      'linear-gradient(121deg, rgba(238, 239, 240 , 0.1), rgba(0, 133, 242, 0.20))',
    padding: '75px',
  },
}))

const Products = () => {
  const classes = useStyles()
  const data = useStaticQuery(
    graphql`
      query {
        sunflow: file(relativePath: { eq: "sunflow.png" }) {
          childImageSharp {
            fluid(quality: 90, maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wsm: file(relativePath: { eq: "wsm-252.png" }) {
          childImageSharp {
            fluid(quality: 90, maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tester: file(relativePath: { eq: "tester.png" }) {
          childImageSharp {
            fluid(quality: 90, maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const sunflow = data.sunflow.childImageSharp.fluid
  const wsm = data.wsm.childImageSharp.fluid
  const tester = data.tester.childImageSharp.fluid
  return (
    <Grid
      container
      direction="row"
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.content}
      id="products"
    >
      <Grid
        container
        direction="row"
        spacing={0}
        alignItems="center"
        justify="center"
        className={classes.row}
      >
        {/* <Grid item sm={11}>
        <Typography variant="h3" className={classes.title}>
          Some of our work
        </Typography>
      </Grid> */}

        <Grid item sm={6} className={classes.gridItem}>
          <Grid alignItems="center" justify="center" container>
            <Grid item sm={8}>
              <Img fluid={sunflow} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} md={5} className={classes.gridItemEven}>
          <Grid alignItems="center" justify="center" container>
            <Grid item sm={11} className={classes.text}>
              <Typography variant="h4" gutterBottom>
                Smart Thermostat
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <Typography variant="body2" gutter>
                Our client needed to catch up with their competition on smart
                devices. But their growing business had them buried in sales and
                operations. They tasked us to present them with a WIFI device
                that could activate their radiators in a more efficient way.
              </Typography>
              <Grid item sm={11} className={classes.text}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    Scroll.scroller.scrollTo('contact-us', {
                      duration: 500,
                      delay: 100,
                      smooth: true,
                      offset: -100,
                    })
                  }}
                >
                  Contact Us
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        direction="row"
        spacing={0}
        alignItems="center"
        justify="center"
        className={classes.rowNotEven}
      >
        <Grid item sm={6} className={classes.gridItem}>
          {' '}
          <Grid alignItems="center" justify="center" container>
            <Grid item sm={11} className={classes.text}>
              <Typography variant="h4" gutterBottom>
                Smart Snow remover
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <Typography variant="body2" gutter>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{' '}
              </Typography>
              <Grid item sm={11} className={classes.text}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    Scroll.scroller.scrollTo('contact-us', {
                      duration: 500,
                      delay: 100,
                      smooth: true,
                      offset: -100,
                    })
                  }}
                >
                  Contact Us
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} className={classes.gridItemEven}>
          <Grid alignItems="center" justify="center" container>
            <Grid item sm={9} md={8}>
              <Img fluid={wsm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid
        container
        direction="row"
        spacing={0}
        alignItems="center"
        justify="center"
        className={classes.rowNotEven}
      >
        <Grid item sm={6} className={classes.gridItem}>
          <Grid alignItems="center" justify="center" container>
            <Grid item sm={8}>
              <Img fluid={tester} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} md={5} className={classes.gridItemEven}>
          <Grid alignItems="center" justify="center" container>
            <Grid item sm={11} className={classes.text}>
              <Typography variant="h4" gutterBottom>
                Cable Tester
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <Typography variant="body2" gutter>
                It was a simple idea. But there just wasn’t enough money in it
                to get their R&D department on it. There were always other
                priorities. So they delegated the whole project to us. We
                designed, prototyped and sources manufacturing.
              </Typography>
              <Grid item sm={11} className={classes.text}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    Scroll.scroller.scrollTo('contact-us', {
                      duration: 500,
                      delay: 100,
                      smooth: true,
                      offset: -100,
                    })
                  }}
                >
                  Contact Us
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Products
