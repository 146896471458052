import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '150px',
    color: 'black',
    textAlign: 'center',
    backgroundImage:
      'linear-gradient(121deg, rgba(0, 133, 242, 0.20), rgba(238, 239, 240 , 0.1))',
  },
}))
const Footer = () => {
  const classes = useStyles()
  return (
    <footer>
      <Grid
        container
        direction="row"
        spacing={15}
        alignItems="center"
        justify="center"
        className={classes.content}
      >
        <Typography variant="body2">
          {' '}
          ©Qiao {new Date().getFullYear()}
        </Typography>
      </Grid>
    </footer>
  )
}

export default Footer
